import React, { useContext, useEffect, useState } from 'react';
import './styles/NavigationMenu.css';
import { LocationContext } from '../context/LocationContext';
import useCreateDefaultUser from '../hooks/useCreateDefaultUser';

const NavigationMenu = ({ activeIndex, setActiveIndex, onAddCurrentLocationMarker }) => {
    const [indicatorStyle, setIndicatorStyle] = useState({});
    const { userProfile } = useCreateDefaultUser();
    const { location, requestLocation } = useContext(LocationContext)

    const navigationItems = [{
        icon: 'flame-outline',
        name: 'méchants plans'
    }, {
        icon: activeIndex === 1 ? 'add-outline' : 'map-outline',
        name: 'carte',
        onClick: activeIndex === 1 ? onAddCurrentLocationMarker : null
    }, {
        icon: 'person-outline',
        name: 'profile'
    }];

    if (userProfile?.role === 'admin') {
        navigationItems.push({
            icon: 'hammer-outline',
            name: 'admin'
        });
    }

    useEffect(() => {
        const containerWidth = document.querySelector('.navigation ul').offsetWidth;
        const itemWidth = containerWidth / navigationItems.length;
        const indicatorWidth = 70; // Largeur de l'indicateur en pixels
        const newIndicatorStyle = {
            transform: `translateX(${itemWidth * activeIndex + itemWidth / 2 - indicatorWidth / 2}px)`
        };
        setIndicatorStyle(newIndicatorStyle);
    }, [activeIndex, navigationItems.length]);

    const handleItemClick = (index, onClick) => {
        setActiveIndex(index);
        if (onClick) {
            onClick();
        }
    };
    const handleGeolocationBarClick = () => {
        requestLocation();
    };

    return (
        <div>
            {
                !location && activeIndex === 1 &&
                <div onClick={handleGeolocationBarClick} className="geolocation-bar">
                    Activer la géolocalisation pour une meilleure experience
                </div>
            }
            <div className="container">
                <br/>
            </div>
            <div className="navigation">
                <ul>
                    {navigationItems.map((item, index) => (
                        <li
                            className={`list ${activeIndex === index ? 'active' : ''}`}
                            key={index}
                            onClick={() => handleItemClick(index, item.onClick)}
                        >
                            <a href="#">
                                <span className="icon">
                                    <ion-icon name={item.icon}></ion-icon>
                                </span>
                                <span className="text">{item.name}</span>
                            </a>
                        </li>
                    ))}
                    <div
                        className="indicator"
                        style={indicatorStyle}
                    ></div>
                </ul>
            </div>
        </div>
    );
};

export default NavigationMenu;