import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveToken } from "./utils/api";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging(app);

const requestNotificationsPermission = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
        if (currentToken) {
            console.log('FCM Token:', currentToken);
            // Envoyer ce token à votre serveur pour pouvoir envoyer des notifications
            saveTokenToServer(currentToken);
            return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (error) {
        console.error('An error occurred while retrieving token. ', error);
    }
};

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Customiser l'affichage de la notification ici
});

const saveTokenToServer = async (token) => {
    // Implémentez cette fonction pour envoyer le token à votre backend
    try {
        await saveToken(token);
        console.log('Token saved successfully');
    } catch (error) {
        console.error('Error saving token:', error);
    }
};

export { requestNotificationsPermission, messaging };